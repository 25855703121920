.search-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.search-form {
  display: flex;
  width: 400px;
}

.search-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  color: black;
}

.search-input:disabled {
  background-color: #333;
  color: white;
}

.search-input:disabled::placeholder {
  color: white;
  opacity: 1;
}

.search-input:disabled::-webkit-input-placeholder {
  color: white;
}

.search-input:disabled::-moz-placeholder {
  color: white;
}

.search-input:disabled:-ms-input-placeholder {
  color: white;
}

.search-button {
  padding: 8px 12px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 14px;
}

.suggestions-container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  color: black;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
