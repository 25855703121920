/* Screener.css */

.screener-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  color: #000; /* Color de texto general */
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  color: #fff; /* Color de fuente blanca para Stock Screener */
  padding: 10px;
  border-radius: 5px;
}

h2 {
  text-align: center;
  color: #fff; /* Color de fuente blanca para Financial Data */
  padding: 10px;
  border-radius: 5px;
}

.filters {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filters select {
  margin: 0 10px;
  padding: 8px; /* Reduce el padding */
  font-size: 12px; /* Reduce el tamaño de la fuente */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.table-container {
  overflow-x: auto; /* Permite el desplazamiento horizontal si es necesario */
  overflow-y: auto; /* Permite el desplazamiento vertical */
  position: relative; /* Para el posicionamiento del encabezado fijo */
}

.stock-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.stock-table th, .stock-table td {
  border: 1px solid #ddd;
  padding: 6px; /* Reduce el padding para hacer las celdas más pequeñas */
  text-align: left;
  font-size: 12px; /* Reduce el tamaño de la fuente */
}

.stock-table th {
  background-color: #f4f4f4;
  color: #333;
  position: sticky; /* Fija el encabezado */
  top: 0; /* Posición en la parte superior */
  z-index: 10; /* Asegura que el encabezado esté por encima del contenido */
}

.stock-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.stock-table tr:hover {
  background-color: #f1f1f1;
}

.filters-container {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group label {
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.filter-group select {
  padding: 6px; /* Reduce el padding */
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.results-counter {
  margin: 20px 0;
  font-weight: 500;
}

.stock-table {
  width: 100%; /* Asegúrate de que la tabla ocupe todo el ancho disponible */
  border-collapse: collapse;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stock-table th,
.stock-table td {
  padding: 6px; /* Reduce el padding para hacer las celdas más pequeñas */
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 12px; /* Reduce el tamaño de la fuente */
}

.stock-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  /* white-space: nowrap; */ /* Eliminar para permitir que el texto se divida en varias líneas */
}

.stock-table tr:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .filters-grid {
    grid-template-columns: 1fr;
  }
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  color: #666;
}

.stock-table tr td.loading-cell {
  text-align: center;
  color: #666;
  padding: 20px;
}

/* Optimizar el rendimiento de las animaciones */
.stock-table tr {
  will-change: transform;
  transition: background-color 0.2s ease;
}

/* Optimizar el scrolling */
.table-container {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.stock-row:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease;
}

.sort-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 5px;
  margin-left: 5px;
  font-size: 14px;
  color: #666;
}

.sort-button:hover {
  color: #000;
}

/* Ajusta el padding del encabezado para acomodar el botón */
.stock-table th {
  padding-right: 25px; /* Ajusta el padding del encabezado */
}

/* Ajusta el ancho de las columnas según sea necesario */
.stock-table th:nth-child(1), .stock-table td:nth-child(1) {
  min-width: 80px; /* Ticker */
}

.stock-table th:nth-child(2), .stock-table td:nth-child(2) {
  min-width: 200px; /* Name */
}

.stock-table th:nth-child(3), .stock-table td:nth-child(3) {
  min-width: 120px; /* Sector */
}

.stock-table th:nth-child(4), .stock-table td:nth-child(4) {
  min-width: 120px; /* Country */
}

.stock-table th:nth-child(5), .stock-table td:nth-child(5) {
  min-width: 120px; /* Upside (%) */
}

.stock-table th:nth-child(6), .stock-table td:nth-child(6) {
  min-width: 120px; /* Market Cap */
}

.stock-table th:nth-child(7), .stock-table td:nth-child(7) {
  min-width: 100px; /* EPS */
}

.stock-table th:nth-child(8), .stock-table td:nth-child(8) {
  min-width: 120px; /* Book Value */
}

.stock-table th:nth-child(9), .stock-table td:nth-child(9) {
  min-width: 150px; /* Net Debt Growth (%) */
}

.stock-table th:nth-child(10), .stock-table td:nth-child(10) {
  min-width: 150px; /* Total Assets Growth (%) */
}

.stock-table th:nth-child(11), .stock-table td:nth-child(11) {
  min-width: 150px; /* Net Property Growth (%) */
}

.scrollable-table {
  overflow-x: auto; /* Permite el desplazamiento horizontal */
  overflow-y: auto; /* Permite el desplazamiento vertical */
  max-height: 800px; /* Ajusta la altura máxima a 800px */
}
