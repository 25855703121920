body {
  background-color: #121212; 
  color: #ffffff; 
  font-family: 'Arial', sans-serif;
}


.stock-detail-container {
  display: flex;
  height: 100vh; 
}


.sidebar {
  background-color: #1e1e1e; 
  padding: 20px;
  width: 250px; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar h2 {
  margin-bottom: 20px;
}

.sidebar-button {
  background-color: #333; 
  color: #ffffff; 
  border: none;
  padding: 15px; 
  margin: 10px 0; 
  width: 100%; 
  text-align: left; 
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.1s ease-in-out; 
}

.sidebar-button:hover {
  background-color: #555; 
}

.sidebar-button:active {
  transform: translateY(2px); 
}


.main-content {
  flex-grow: 1; 
  padding: 20px;
  overflow-y: auto; 
}


.stock-info {
  background-color: #292929; 
  border-radius: 10px; 
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px; 
  border: 1px solid #444; 
}

.stock-info-row {
  display: flex; 
  align-items: center; 
  gap: 10px; 
}

.stock-info-row div {
  flex: 1; 
  min-width: 60px; 
}

.stock-info h1 {
  margin: 5px 0; 
  font-size: 1.3rem; 
}

.ticker {
  font-size: 0.75rem; 
}

.upside-positive {
  color: #4caf50; 
}

.upside-negative {
  color: #f44336; 
}


.data-card {
  background-color: #292929; 
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px; 
  border: 1px solid #444; 
}


table {
  width: 100%; 
  border-collapse: collapse; 
  margin-bottom: 30px; 
}

th, td {
  padding: 12px; 
  text-align: left; 
  background-color: #1e1e1e; 
  border-bottom: 1px solid #333; 
  font-size: 0.9rem; 
  color: #ffffff; 
}

th {
  background-color: #333; 
  font-weight: bold; 
  text-transform: uppercase; 
}

tr:hover td {
  background-color: #2b2b2b; 
}


td:first-child, th:first-child {
  border-left: none; 
}

td:last-child, th:last-child {
  border-right: none; 
}


.table-container {
  overflow-x: auto;
  max-width: 100%;
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

/* Estilos específicos para la tabla DCF */
.header-row {
  background-color: #2c2c2c;
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-row td {
  background-color: #2c2c2c;
  color: #4CAF50;
  font-weight: bold;
  text-align: center;
  padding: 12px;
}

.label-column {
  text-align: left;
  padding: 12px;
  width: 200px;
  background-color: #1a1a1a;
  position: sticky;
  left: 0;
  z-index: 2;
  font-weight: bold;
}

.value-column {
  text-align: right;
  padding: 12px;
  width: 100px;
  font-family: 'Consolas', monospace;
  font-size: 0.9em;
}

/* Estilos para filas especiales */
.special-row {
  background-color: #1a1a1a;
  font-weight: bold;
}

.unlevered-row {
  background-color: #1a1a1a;
  color: #2196F3;
}

.total-debt-row {
  background-color: #1a1a1a;
  color: #f44336;
}

/* Estilos para valores negativos/positivos */
.negative-value {
  color: #f44336;
}

tr:hover:not(.header-row) td {
  background-color: #2c2c2c;
}

/* Mantener el fondo de la columna de etiquetas al hacer hover */
tr:hover:not(.header-row) td.label-column {
  background-color: #1a1a1a;
}

@media (max-width: 768px) {
  table th, table td {
    padding: 10px;
    font-size: 0.75rem; 
  }
}

.sidebar-button.disabled {
  background-color: #d3d3d3; 
  cursor: not-allowed;
}

.news-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #292929;
  border-radius: 10px;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.news-card {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.2s;
}

.news-card:hover {
  transform: translateY(-5px);
}

.news-card h3 {
  margin: 0 0 10px 0;
  color: #fff;
}

.news-card p {
  color: #ccc;
  margin-bottom: 15px;
}

.news-card a {
  color: #4CAF50;
  text-decoration: none;
}

.news-card .news-date {
  display: block;
  color: #888;
  font-size: 0.9em;
  margin-top: 10px;
}

.sidebar-button.active {
  background-color: #4CAF50;
  color: white;
}

.sidebar-button.active:hover {
  background-color: #45a049;
}

.limit-reached-message {
  text-align: center;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 2rem;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.auth-button {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  background-color: #007bff;
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #0056b3;
}
